import React from 'react';
import { causeMarketingData } from '../../data/causeMarketing'

const CauseMarketing: React.FC = () => {
  return (
    <div className='powered-by-caring-image-container'>
        {causeMarketingData && causeMarketingData.map(data => (
            <div className='powered-by-caring-group' key={data.id}>
                <a href={data.url} target='_blank' rel='noreferrer'>
                    <img
                        className='powered-by-caring-image'
                        src={data.image}
                        alt={data.name}
                    />
                </a>
                <a href={data.url} target='_blank'>{data.name}</a>
                <p>{data.description}</p>
            </div>
        ))}
    </div>
  );
};

export default CauseMarketing;