import React from 'react';
import Head from 'next/head';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';

export interface CarouselItem extends ImageProps {
  cta?: JSX.Element;
}

const Hero: React.FC = () => {
  return (
    <>
      <Head>
        <link rel='preconnect' href='https://res.cloudinary.com' />
        <link rel='dns-prefetch' href='https://res.cloudinary.com' />
      </Head>

      <div className='hero__wrapper'>
        <div className='hero__image'>
          <Image
            layout='fill'
            objectFit='cover'
            priority
            src='https://res.cloudinary.com/twomaidsengi/image/upload/v1740591108/hubsite-reskin/Website_Hero_TM_670px_March.webp'
          />
        </div>

        <div className='hero__content'>
          <img
            src='https://res.cloudinary.com/twomaidsengi/image/upload/v1740591273/hubsite-reskin/shine_into_spring.webp'
            alt='more free time'
            className='hero__content--image'
          />
          <div className='hero__ctas'>
            <Link href='/book'>
              <a className='btn-tm-cta'>BOOK YOUR CLEANING</a>
            </Link>
            <Link href='/book'>
              <a className='btn-tm-cta'>CALCULATE YOUR PRICE</a>
            </Link>
          </div>
        </div>
        <div className='hero-circles'>
          <div className='hero-circle'></div>
          <div className='hero-circle'></div>
          <div className='hero-circle'></div>
        </div>
      </div>
    </>
  );
};

export default Hero; 
