export const causeMarketingData = [
  {
    id: 1,
    name: 'Cleaning for a Reason',
    url: 'https://cleaningforareason.org/',
    image: 'https://res.cloudinary.com/twomaidsengi/image/upload/v1740686860/hubsite-reskin/CFR_Lavender-Tagline_side_11-2_tm.webp',
    description: "We selflessly donate time and services to support cancer patients and their families. Our goal is to remove the burden of cleaning and allow those recovering from surgery, in active treatment, or in hospice to focus on their health and spending valuable time with loved ones.",
  },
  {
    id: 2,
    name: 'The Finley Project',
    url: 'https://www.thefinleyproject.org/',
    image: 'https://res.cloudinary.com/twomaidsengi/image/upload/v1713540352/hubsite-reskin/TheFinleyProject.webp',
    description: 'We are committed to providing cleaning services for mothers who have experienced the unimaginable - the loss of an infant. We are part of the devoted team that provides a lifeline for mothers in the minutes, hours and days after infant loss through a 7-Part Holistic Program that supports each mother physically, emotionally and spiritually at no financial cost to them.',
  },
  {
    id: 3,
    name: 'Alliance for HOPE',
    url: 'https://www.allianceforhope.com/',
    image: 'https://res.cloudinary.com/twomaidsengi/image/upload/v1682368766/hubsite-reskin/alliance_for_hope.webp',
    description: "We support this nationally recognized, evidence based program that's helping thousands of survivors of domestic violence, sexual assault, and child abuse every year.",
  },
  {
    id: 4,
    name: 'Brady Wynn Foundation',
    url: 'https://bradywynnfoundation.org/',
    image: 'https://res.cloudinary.com/twomaidsengi/image/upload/v1740687120/hubsite-reskin/Brady_Wynn_Foundation_Logo_With_Writing_1.webp',
    description: "Two Maids supports this organization on a national level to help families with babies that have life threatening neurological complications. Our goal is to provide time for healing, while we handle the burden of keeping their homes clean."
  }
];