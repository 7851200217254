import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Section from '../Section';
import CauseMarketing from '../CauseMarketing';

export function HomeDifferenceSection(): JSX.Element {
  return (
    <Section className='home-difference'>
      <Row className='justify-content-center'>
        <Col xs={12} md={8} className='text-center'>
          <h2>Powered by Caring</h2>
        </Col>
      </Row>
      <Row>
        <p>
          Two Maids organization fosters a culture of compassion, interconnectedness, and grit, creating a welcoming environment akin to home. 
          We not only prioritize care within our internal staff but also extend our support to communities and nonprofit organizations whose values 
          and programs align naturally with those of Two Maids. For more information about each organization partner, please visit the organization’s 
          website linked below.
        </p>
      </Row>
      <CauseMarketing />
    </Section>
  );
}
